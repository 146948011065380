<template>
    <div>
       <div class="card card-custom mt-5">
          <div class="card-body">
             <!-- Filter -->
             <div>
                <div class="m-form m-form--fit m--margin-bottom-20">
                   <div class="row">
                      <div class="col-12 mt-10">
                         <table class="table table-bordered">
                            
                            <tbody>
<!--                               <tr>-->
<!--                                  <td>-->
<!--                                     <span>{{$t('client_reports.client_sales')}}</span>-->
<!--                                     <span class="float-right ml-2">-->
<!--                                        <router-link :to="{name: 'stocks-report.list'}" target="_blank">-->
<!--                                            <span class="float-right">{{$t('view')}}</span>-->
<!--                                        </router-link>-->
<!--                                     </span>-->
<!--                                  </td>-->
                                  <!-- <td>
                                     <span>{{$t('client_reports.daily_payments')}}</span>
                                     <router-link :to="{name: 'daily-brief-report-payments.list'}" target="_blank">
                                        <span class="float-right ml-2">| {{$t('details')}}</span>
                                    </router-link>
                                    <router-link :to="{name: 'daily-brief-report-payments.list'}" target="_blank">
                                        <span class="float-right">{{$t('summery')}}</span>
                                    </router-link>
                                  </td> -->
<!--                               </tr>-->
                              <tr v-if="$can('report_sales.report_customer_transactions')">
                                <td class="d-flex justify-content-between">
                                  <span>{{ $t('MENU.customer_transactions_report') }}</span>
                                  <div>
                                    <router-link :to="{name: 'customer-transactions-report.list'}" target="_blank">
                                      {{ $t('view') }}
                                    </router-link>
                                  </div>
                                </td>
                              </tr>

                               <tr v-if="$can('report_clients.report_clients_debtors')">
                                  <td>
                                     <span>{{$t('client_reports.aged_debtors')}}</span>
                                     <router-link :to="{name: 'clients-debtors-report.list'}" target="_blank">
                                        <span class="float-right ml-2">{{$t('view')}}</span>
                                    </router-link>
                                  </td>
                               </tr>
                               <tr v-if="$can('report_clients.clients_list')">
                                  <td>
                                     <span>{{$t('client_reports.clients_list')}}</span>
                                     <router-link :to="{name: 'clients-reports.list'}" target="_blank">
                                        <span class="float-right ml-2">{{$t('view')}}</span>
                                    </router-link>
                                  </td>
                                  
                               </tr>
                               <tr v-if="$can('report_clients.report_clients_balance')">
                                  <td>
                                     <span>{{ $t('client_reports.clients_balance')}}</span>
                                     <router-link :to="{name: 'clients-balance-report.list'}" target="_blank">
                                        <span class="float-right ml-2">{{$t('view')}}</span>
                                    </router-link>
                                  </td>
                                 
                               </tr>
                               <tr v-if="$can('report_clients.report_clients_sales')">
                                  <td>
                                     <span>{{$t('client_reports.clients_sales')}}</span>
                                     <router-link :to="{name: 'clients-sales-report.list'}" target="_blank">
                                        <span class="float-right ml-2">{{$t('view')}}</span>
                                    </router-link>
                                  </td>
                               </tr>
                               <tr v-if="$can('report_clients.report_clients_payments')">
                                  <td>
                                     <span>{{$t('client_reports.clients_payments')}}</span>
                                     <router-link :to="{name: 'clients-payments-report.list'}" target="_blank">
                                        <span class="float-right ml-2">{{$t('view')}}</span>
                                    </router-link>
                                  </td>
                               </tr>
<!--                               <tr>-->
<!--                                  <td>-->
<!--                                     <span>{{$t('client_reports.clients_statement')}}</span>-->
<!--                                     <router-link :to="{name: 'daily-product-sales.list'}" target="_blank">-->
<!--                                        <span class="float-right ml-2">{{$t('view')}}</span>-->
<!--                                    </router-link>-->
<!--                                  </td>-->
<!--                               </tr>-->
                               <tr v-if="$can('report_clients.report_clients_appointments')">
                                  <td>
                                     <span>{{$t('client_reports.clients_appointments')}}</span>
                                     <router-link :to="{name: 'clients-appointments-report.list'}" target="_blank">
                                        <span class="float-right ml-2">{{$t('view')}}</span>
                                    </router-link>
                                  </td>
                               </tr>
                               <tr v-if="$can('report_clients.report_simple_customers_statment')">
                                  <td>
                                     <span>{{$t('MENU.simple_customer_balance_report')}}</span>
                                     <router-link :to="{name: 'simple-clients-balance-report.list'}" target="_blank">
                                        <span class="float-right ml-2">{{$t('view')}}</span>
                                    </router-link>
                                  </td>
                               </tr>
<!--                               <tr v-if="$can('report_clients.report_clients_installment')">-->
<!--                                  <td>-->
<!--                                     <span>{{$t('client_reports.clients_installments')}}</span>-->
<!--                                     <router-link :to="{name: 'clients-installment-report.list'}" target="_blank">-->
<!--                                        <span class="float-right ml-2">{{$t('view')}}</span>-->
<!--                                    </router-link>-->
<!--                                  </td>-->
<!--                               </tr>-->
                            </tbody>
                         </table>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>
 </template>
<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

    export default {
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.clients_reports")}]);

        },
    }
</script>
 <style scoped>
 td{
    padding: 1rem;
 }
 </style>